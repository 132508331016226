<template>
  <div class="help-desk" :class="{ 'show-help-popup': info }">
    <a href="#" class="close" @click.prevent="$emit('closeInfo')"></a>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/business-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_business") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/econom-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_economy") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/ecomon-fixed-arms-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_fixed_arms") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/econom-fixed-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_fixed") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/econom-front-rows-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_front_rows") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/space-plus-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_space_plus") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/a-zone-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_a_zone") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/b-zone-avail.png" alt="" />
      </div>
      <div class="hd-col">{{ $t("upgrade-seat.help_b_zone") }}</div>
    </div>
    <div class="hd-row">
      <div class="hd-col">
        <img src="@/assets/i/seats/separator.svg" alt="" />
      </div>
      <div class="hd-col">
        {{ $t("upgrade-seat.help_child") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Help",
  props: ["info"]
};
</script>

<style scoped></style>
