<template>
  <div class="info-column">
    <meta-title :title="metaTitle"></meta-title>
    <div class="board">
      <h4>
        {{ $t("planes.board") }}:
        <strong>{{
          replaceTypeAir(type) ? replaceTypeAir(type).name : type
        }}</strong>
      </h4>

      <img
        :src="require(`@/assets/i/plane-${type.toLowerCase()}.svg`)"
        alt=""
      />
    </div>
    <success-payment
      :order="
        orders.find(order =>
          order.items.find(it => it.id === getLayoutUpgrade.id)
        )
      "
      v-if="$route.query.payment === 'upgrade-success' && !!$route.query.guid"
      @updateSeat="completeOrder"
    ></success-payment>
    <cancel-upgrade
      :order="
        orders.find(order =>
          order.items.find(it => it.id === getLayoutUpgrade.id)
        )
      "
      v-if="$route.query.payment === 'upgrade-cancel'"
      @cancelUpgradeOrder="cancelUpgradeOrder"
    ></cancel-upgrade>
    <help v-if="!selected" :info="info" @closeInfo="$emit('closeInfo')"></help>

    <div
      v-else-if="
        selected &&
          !orders.find(order =>
            order.items.find(it => it.id === getLayoutUpgrade.id)
          )
      "
      class="selected-place-info"
    >
      <a
        href="#"
        v-if="!Object.keys($route.query).length"
        class="close"
        @click.prevent="closeStepOne"
      ></a>
      <div class="tabs">
        <service-provision-rules></service-provision-rules>
        <restrictions></restrictions>
        <div
          class="tab"
          :class="{
            hidden: $route.query.restriction || $route.query.rules
          }"
          data-tab="form"
        >
          <form class="no-padding">
            <div class="row">
              <div class="col col-left col-24 col-sl-11 col-s-24">
                <h4>
                  {{ $t("planes.select_seat") }}
                  <strong>{{ selected }}</strong>
                </h4>
                <div
                  class="seats-row"
                  :class="[
                    selectedColumn.length > 2 ? 'three-seats' : 'two-seats'
                  ]"
                >
                  <div
                    class="item illuminator"
                    v-if="
                      selectedColumn.find(
                        it => it.seatNumber.replace(/[0-9]/g, '') === 'A'
                      )
                    "
                  ></div>
                  <div
                    :key="ix"
                    class="item "
                    :class="[
                      seat.class === 'seat-business'
                        ? 'seat-space-plus'
                        : seat.class
                            .replace('-fixed-arms', '')
                            .replace('-fixed', '')
                            .replace('-b-zone', '')
                            .replace('-front-rows', '')
                            .replace('-a-zone', ''),
                      seat.seatNumber === selected ? 'selected' : ''
                    ]"
                    v-for="(seat, ix) in selectedColumn"
                  >
                    <div class="label">{{ seat.seatNumber }}</div>
                    <div class="here" v-if="seat.seatNumber === user.seat">
                      <span>{{ $t("upgrade-seat.info_seat_here") }}</span>
                    </div>
                  </div>
                  <div
                    class="item illuminator"
                    v-if="
                      !selectedColumn.find(
                        it => it.seatNumber.replace(/[0-9]/g, '') === 'A'
                      ) && selectedColumn.find(it => it.group === 3)
                    "
                  ></div>
                </div>

                <div
                  class="field-cor-row mt-35"
                  v-if="!(isBusyPlace || preload)"
                >
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="value"
                        :value="1"
                        type="radio"
                        id="input-01"
                        v-model="selectType"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-01">{{
                      $t("upgrade-seat.info_i_need_transfer")
                    }}</label>
                  </div>
                </div>
                <div
                  v-if="!(isBusyPlace || preload)"
                  class="field-cor-row"
                  :class="{ 'input-disabled': !checkNextSeat }"
                >
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="value"
                        :value="2"
                        type="radio"
                        id="input-02"
                        v-model="selectType"
                        :disabled="!checkNextSeat"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-02">{{
                      $t("upgrade-seat.info_i_need_buy_seat")
                    }}</label>
                  </div>
                </div>
                <div
                  v-if="!(isBusyPlace || preload)"
                  class="field-cor-row "
                  :class="{ 'input-disabled': !checkRowUpgrade }"
                >
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="value"
                        :value="3"
                        v-model="selectType"
                        type="radio"
                        id="input-03"
                        :disabled="!checkRowUpgrade"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-03">{{
                      $t("upgrade-seat.info_i_need_buy_row")
                    }}</label>
                  </div>
                </div>
                <p style="margin-top:10px;">
                  Форма оплаты:
                </p>
                <div class="field-cor-row" v-if="!(isBusyPlace || preload)">
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="paymentType"
                        type="radio"
                        id="input-011"
                        :value="1"
                        v-model="paymentType"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-011">{{
                      $t("planes.payment_by_portal")
                    }}</label>
                  </div>
                </div>
                <div class="field-cor-row" v-if="!(isBusyPlace || preload)">
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="paymentType"
                        :value="2"
                        v-model="paymentType"
                        type="radio"
                        id="input-022"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-022">{{
                      $t("planes.payment_by_terminal")
                    }}</label>
                  </div>
                </div>
                <div class="field-cor-row" v-if="!(isBusyPlace || preload)">
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="paymentType"
                        :value="3"
                        v-model="paymentType"
                        type="radio"
                        id="input-023"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-023">{{
                      $t("planes.payment_cashe")
                    }}</label>
                  </div>
                </div>
              </div>

              <preloader
                v-if="isBusyPlace || preload"
                :isBusyPlace="isBusyPlace"
                :preload="preload"
                @closePreloader="closePreloader"
              ></preloader>
              <div
                v-else
                style="display:flex; flex-direction:column;justify-content:space-between"
                class="col col-right col-24 col-sl-11 offset-sl-2 col-s-24 offset-s-0"
              >
                <p class="welcome mt-35">
                  {{ upgradeDescription[$i18n.locale] }}
                </p>

                <div class="cols">
                  <div class="price">
                    <p>{{ $t("planes.price") }}</p>
                    <p>
                      <em>
                        <animated-number :number="price"></animated-number>
                        ₽</em
                      >
                    </p>
                  </div>

                  <div class="features" v-if="featuresSelected.length">
                    <p>
                      <strong>{{ $t("planes.features") }}:</strong>
                    </p>
                    <p :key="fidx" v-for="(feature, fidx) in featuresSelected">
                      {{ $t(`planes.${feature}`) }}
                    </p>
                  </div>
                </div>

                <div class="field-cor-row mt-35">
                  <div class="field-cor-col">
                    <div class="input-checkbox">
                      <input
                        name="value"
                        :value="1"
                        v-model="checkReadOne"
                        type="checkbox"
                        id="input-05"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-05"
                      >{{ $t("planes.terms_part_1") }}
                      <router-link
                        :to="{
                          name: 'UpgradeSeat',
                          query: { rules: 1 }
                        }"
                        data-activate-tab="info"
                        >{{ $t("planes.terms_part_2") }}</router-link
                      >{{ $t("planes.terms_part_3") }}</label
                    >
                  </div>
                </div>

                <div class="buttons">
                  <input
                    type="button"
                    class="submit small"
                    :class="{ buy: !checkSubmit }"
                    :disabled="!checkSubmit"
                    :value="$t('planes.upgrade_seat_button')"
                    @click="submitStepOne()"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else class="selected-place-info">
      <a
        href="#"
        v-if="!Object.keys($route.query).length"
        class="close"
        @click.prevent="cancelUpgradeOrder"
      ></a>

      <div class="tabs">
        <div
          class="tab"
          data-tab="form"
          :class="{
            hidden: $route.query.restriction || $route.query.rules
          }"
        >
          <form class="no-padding">
            <div class="row">
              <div class="col col-left col-24 col-sl-11 col-s-24">
                <h4>{{ $t("planes.order_details") }}</h4>

                <div
                  class="seats-row"
                  :class="[
                    selectedColumn.length > 2 ? 'three-seats' : 'two-seats'
                  ]"
                >
                  <div
                    class="item illuminator"
                    v-if="
                      selectedColumn.find(
                        it => it.seatNumber.replace(/[0-9]/g, '') === 'A'
                      )
                    "
                  ></div>
                  <div
                    :key="ix"
                    class="item "
                    :class="[
                      seat.class === 'seat-business'
                        ? 'seat-space-plus'
                        : seat.class
                            .replace('-fixed-arms', '')
                            .replace('-fixed', '')
                            .replace('-b-zone', '')
                            .replace('-front-rows', '')
                            .replace('-a-zone', ''),
                      seat.seatNumber === selected ? 'selected' : ''
                    ]"
                    v-for="(seat, ix) in selectedColumn"
                  >
                    <div class="label">{{ seat.seatNumber }}</div>
                    <div class="here" v-if="seat.seatNumber === user.seat">
                      <span>{{ $t("upgrade-seat.info_seat_here") }}</span>
                    </div>
                  </div>
                  <div
                    class="item illuminator"
                    v-if="
                      !selectedColumn.find(
                        it => it.seatNumber.replace(/[0-9]/g, '') === 'A'
                      ) && selectedColumn.find(it => it.group === 3)
                    "
                  ></div>
                </div>

                <div class="restrictions" v-if="!(isBusyPlace || preload)">
                  <strong>{{ $t("planes.order_processing") }}</strong>
                  <p>
                    {{ $t("planes.order_processing_details") }}
                  </p>
                </div>

                <div
                  class="field-cor-row mt-35"
                  style="opacity: 0.6;"
                  v-if="!(isBusyPlace || preload)"
                >
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="paymentType"
                        type="radio"
                        id="input-012"
                        disabled
                        :value="1"
                        v-model="paymentType"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-012">{{
                      $t("planes.payment_by_portal")
                    }}</label>
                  </div>
                </div>
                <div
                  class="field-cor-row"
                  style="opacity: 0.6;"
                  v-if="!(isBusyPlace || preload)"
                >
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="value"
                        :value="2"
                        v-model="paymentType"
                        type="radio"
                        disabled
                        id="input-013"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-013">{{
                      $t("planes.payment_by_terminal")
                    }}</label>
                  </div>
                </div>
                <div
                  class="field-cor-row"
                  style="opacity: 0.6;"
                  v-if="!(isBusyPlace || preload)"
                >
                  <div class="field-cor-col">
                    <div class="input-radio">
                      <input
                        name="value"
                        :value="3"
                        v-model="paymentType"
                        type="radio"
                        disabled
                        id="input-014"
                      />
                      <span class="checkmark"></span>
                    </div>
                  </div>
                  <div class="field-cor-col">
                    <label for="input-014">{{
                      $t("planes.payment_cashe")
                    }}</label>
                  </div>
                </div>
              </div>
              <preloader
                v-if="isBusyPlace || preload"
                :isBusyPlace="isBusyPlace"
                :preload="preload"
                @closePreloader="closePreloader"
              ></preloader>
              <div
                v-else
                style="display:flex; flex-direction:column;justify-content:space-between"
                class="col col-right col-24 col-sl-11 offset-sl-2 col-s-24 offset-s-0"
              >
                <div class="cols">
                  <div class="price">
                    <p>
                      <strong>{{ $t(`planes.price`) }}</strong>
                    </p>
                    <p>
                      <em>
                        <animated-number :number="price"></animated-number>
                        ₽</em
                      >
                    </p>
                  </div>

                  <div class="features">
                    <p>
                      <strong> {{ $t(`planes.features_select_seat`) }}</strong>
                    </p>
                    <p>
                      <em>{{ selected }}</em>
                    </p>
                    <p class="info">
                      {{ $t(`planes.type_${selectType}`) }}
                    </p>
                  </div>
                </div>

                <div class="field-row" v-if="paymentType === 1">
                  <label for="email">{{ $t("planes.email") }}</label>
                  <input
                    :class="{ is_invalid: errors.email }"
                    type="email"
                    autocomplete="off"
                    name="email"
                    id="email"
                    v-model="form.email"
                  />
                  <p class="error-description" v-if="errors.email">
                    {{ $t("planes.email_error") }}
                  </p>
                </div>

                <div class="row">
                  <div class="col-14 col-m-24" v-if="paymentType === 1">
                    <div class="field-row">
                      <label for="phone">{{ $t("planes.phone") }}</label>
                      <input
                        type="text"
                        autocomplete="nope"
                        :class="{ is_invalid: errors.phone }"
                        placeholder="79031234567"
                        name="phone"
                        id="phone"
                        v-model="form.phone"
                      />
                      <p class="error-description" v-if="errors.phone">
                        {{ $t("planes.phone_error") }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-9 col-m-24 offset-m-0"
                    :class="{ 'offset-1': paymentType === 1 }"
                  >
                    <div class="field-row">
                      <label for="phone" v-html="$t('planes.code')"></label>
                      <input
                        type="password"
                        autocomplete="nope"
                        :class="{ is_invalid: errors.code }"
                        name="code"
                        v-model="form.code"
                        id="password"
                      />
                      <p
                        class="error-description"
                        v-if="errors.code"
                        v-html="$t('planes.code_error')"
                      ></p>
                    </div>
                  </div>
                </div>

                <p class="warning" v-html="$t('planes.not_close_info')"></p>

                <router-link
                  :to="{ name: 'UpgradeSeat', query: { rules: 1 } }"
                  class="warning"
                  replace
                  v-html="$t('planes.terms')"
                ></router-link>

                <div class="buttons">
                  <div class="row">
                    <div class="col-11">
                      <input
                        type="button"
                        class="cancel"
                        value="Отменить"
                        @click.prevent="cancelUpgradeOrder"
                      />
                    </div>
                    <div class="col-11 offset-2">
                      <input
                        type="button"
                        class="submit small"
                        :class="{ buy: !validateForm }"
                        :disabled="!validateForm"
                        value="Оплатить"
                        @click.prevent="validateAndPayment"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <service-provision-rules></service-provision-rules>
      </div>
    </div>
  </div>
</template>

<script>
import Help from "@/components/planes/popup/Help";
import planes from "@/mixins/planes";
import SuccessPayment from "@/components/planes/popup/SuccessPayment";
import ServiceProvisionRules from "@/components/planes/popup/ServiceProvisionRules";
import Restrictions from "@/components/planes/popup/Restrictions";
import CancelUpgrade from "@/components/planes/popup/CancelUpgrade";
import Preloader from "@/components/planes/popup/Preloader";
export default {
  mixins: [planes],
  props: ["selectedColumn", "selected", "type", "info"],
  name: "Info",
  title() {
    return (
      this.$t("pages.upgrade_seat") +
      " - " +
      (this.replaceTypeAir(this.type)
        ? this.replaceTypeAir(this.type).name
        : this.type)
    );
  },
  components: {
    Preloader,
    CancelUpgrade,
    Help,
    SuccessPayment,
    ServiceProvisionRules,
    Restrictions
  },
  mounted() {
    this.$socket.$subscribe("upgradePlace", payload => {
      if (
        payload.order.upgradeSeat.places.find(place => place === this.selected)
      ) {
        this.$router.push({
          path: this.$route.path,
          query: { payment: "upgrade-cancel", selected: this.selected }
        });
      } else {
        payload.order.upgradeSeat.places.forEach(place => {
          const indexPlace = this.planeAirSeats.findIndex(
            p => p.seat === place
          );
          if (indexPlace !== -1) {
            this.updatePlaneByIndex([{ disabled: true }, indexPlace]);
          }
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$socket.$unsubscribe("upgradePlace");
    next();
  },
  computed: {
    featuresSelected() {
      try {
        return this.selectedColumn.find(it => it.seatNumber === this.selected)
          .features;
      } catch (e) {
        return [];
      }
    }
  },
  methods: {
    cancelUpgradeOrder() {
      this.cancelOrder({ id: this.getLayoutUpgrade.id });
      this.selectType = 1;
      this.$emit("selected", false);
    },
    replaceTypeAir(type) {
      const typeInfo = [
        { typeName: "Y100", name: "Sukhoi Superjet 100" },
        { typeName: "SU9", name: "Sukhoi Superjet 100" },
        { typeName: "А320", name: "Аirbus A320" },
        { typeName: "A319", name: "Airbus A319" },
        { typeName: "B777", name: "Boeing 777-300" },
        { typeName: "B773", name: "Boeing 777-300" },
        { typeName: "B777ER", name: "Boeing 777-300ER" },

        { typeName: "B737", name: "Boeing 737-800" },
        { typeName: "A737", name: "Boeing 737-800" },
        { typeName: "B737V2", name: "Boeing 737-800" },
        { typeName: "B737V3", name: "Boeing 737-800" },
        { typeName: "B737V4", name: "Boeing 737-800" },
        { typeName: "A737V2", name: "Boeing 737-800" },

        { typeName: "B744A", name: "Boeing 747-400" },
        { typeName: "B744B", name: "Boeing 747-400" },
        { typeName: "B744С", name: "Boeing 747-400" }
      ];
      const result = typeInfo.find(item => item.typeName === type);
      if (result) {
        return result;
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
