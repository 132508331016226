<template>
  <div class="selected-place-info result-info" style="z-index: 100000">
    <a href="#" @click.prevent="$router.push($route.path)" class="close"></a>

    <div class="tabs">
      <div class="tab" data-tab="form">
        <form class="no-padding">
          <h4 class="biggest">{{ $t("planes.order_details") }}</h4>

          <div class="cols">
            <div class="price">
              <p>
                <strong>{{ $t("planes.features_select_seat") }}</strong>
              </p>
              <p>
                <em>{{ $route.query.selected }}</em>
              </p>
            </div>
          </div>
          <div class="cols">
            <div class="price"></div>
          </div>
          <p class="notice">
            {{ $t("planes.cancel_payment_notice") }}
          </p>

          <div class="buttons">
            <input
              type="button"
              class="submit small"
              @click.prevent="$router.push($route.path)"
              value="Закрыть страницу"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancelUpgrade",
  props: ["order", "type"],
  mounted() {
    this.$emit("cancelUpgradeOrder", {
      guid: this.$route.query.guid
    });
  }
};
</script>

<style scoped></style>
