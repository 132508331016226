<template>
  <div class="tab" data-tab="info" :class="{ hidden: !+$route.query.rules }">
    <h4 class="back">
      <router-link
        :to="{ name: 'UpgradeSeat' }"
        data-activate-tab="form"
      ></router-link>
      <span v-html="$t('upgrade-seat.provision_title')"></span>
    </h4>

    <div v-html="$t('upgrade-seat.provision_text')"></div>
  </div>
</template>

<script>
export default {
  name: "ServiceProvisionRules"
};
</script>

<style scoped></style>
