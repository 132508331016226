<template>
  <div
    class="tab"
    data-tab="info"
    :class="{ hidden: !+$route.query.restriction }"
  >
    <h4 class="back">
      <router-link
        :to="{ name: 'UpgradeSeat' }"
        data-activate-tab="form"
      ></router-link>
      <span>Правила предоставления услуги «Апгрейд места»</span>
    </h4>
    <p>
      Nulla at pharetra sapien. Aliquam erat volutpat. Etiam malesuada nisi
      erat. Maecenas egestas odio justo, id laoreet metus sollicitudin eget.
      Etiam eleifend vehicula dui a bibendum. Mauris interdum, arcu vel
      tincidunt ultricies, nibh augue lacinia odio, ut tincidunt ligula nisi ac
      metus. In pretium dolor id rhoncus molestie. Class aptent taciti sociosqu
      ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse
      fermentum ligula sed tellus tristique sagittis. Integer tempus nisi sit
      amet nunc fringilla blandit.
    </p>

    <p>
      Morbi vel dolor vel dolor aliquam varius in eget mi. Cras et nulla non
      nunc elementum laoreet vel nec ante. Phasellus aliquet vitae odio quis
      faucibus. Sed dapibus pharetra ante, eu lacinia felis. Proin placerat
      vulputate orci ut eleifend. Aenean porttitor consequat felis, a
      ullamcorper erat scelerisque sit amet. Suspendisse potenti.
    </p>
  </div>
</template>

<script>
export default {
  name: "Restrictions"
};
</script>

<style scoped></style>
