<template>
  <div class="col col-right col-24 col-sl-11 offset-sl-2 col-s-24 offset-s-0">
    <div class="preloader-container" v-if="preload">
      <div class="preloader">
        <img src="@/assets/i/preloader-center.svg" class="center" alt="" />
        <img src="@/assets/i/preloader.svg" class="rotate" alt="" />
      </div>
      <p>
        {{ $t("planes.preloader") }}
      </p>
    </div>

    <div class="preloader-container" v-if="isBusyPlace">
      <p>
        {{ $t("planes.error_preloader_message") }}
      </p>
      <a
        href="#"
        @click.prevent="$emit('closePreloader', true)"
        class="success nowrap"
      >
        {{ $t("planes.another_place_button") }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  props: ["isBusyPlace", "preload"]
};
</script>

<style scoped></style>
